<template>
  <div style="text-align: center">
    <ui-loader />
  </div>
</template>

<script>
import { gatewayCheck } from '@/services/auth.service'
import { mapActions } from 'vuex'
import UiLoader from '@/components/UI/Loader.vue'
export default {
  name: 'LoginForgotPassword',
  components: {
    UiLoader,
  },
  async created() {
    const { gtoken, ctoken, url } = this.$route.query
    try {
      const {
        data: { jwt_token, user_token },
      } = await gatewayCheck({ gtoken, ctoken })
      localStorage.setItem('jwt_token', jwt_token)
      localStorage.setItem('user_token', user_token)
      await this.userNavigation()
      this.$router.push(url || { name: 'Home' })
    } catch (err) {
      this.$router.push({ name: 'SignIn', query: { redirect: url || 'Home' } })
    }
  },
  methods: {
    ...mapActions({
      userNavigation: 'user/userNavigation',
    }),
  },
}
</script>
